@import url("https://fonts.googleapis.com/css?family=Manrope");
@import url("https://fonts.googleapis.com/css?family=Rubik");
@import url("https://fonts.googleapis.com/css?family=Sofia");
@import url("https://fonts.googleapis.com/css?family=Montserrat");
@import url("https://fonts.googleapis.com/css?family=Inconsolata");

$blue-colour: #679cf7;
$footer-color: #efeff5;
$dark-blue: #7199de;
$darkest-blue: #1b65e4;
$heading: 3rem;
$project-heading: 2rem;
$project-description: 1.2rem;
$sub-heading: 1.2rem;
$subtitle: 1rem;
$sub-heading-small: 0.8rem;
$dark-background-color: rgb(10, 8, 46);

$flex-row-space-between-properties: (
  display: flex,
  flex-direction: row,
  justify-content: space-between,
);

@mixin flex-row-space-between-container {
  @each $property, $value in $flex-row-space-between-properties {
    #{$property}: $value;
  }
}

:root {
  --background-color: #ffffff;
  --primary-text-color: #0f0f0f;
  --description-color: #5c5c8a;
  --experience-card-background: #f5f5f5;
  --project-card-background: rgb(244, 244, 248);
  --project-card-background-mobile: rgb(244, 244, 248);
  --overview-text-color: #606060;
  --tool-bar-icon-color: #0f0f0f;
  --footer-background: #ffffff;
  --chip-background-color: #d7dfe3;
  --chip-font-color: #0f0f0f;
  --experience-mobile-card-background: rgb(244, 244, 248);
  --experience-card-shadow: rgb(189, 189, 200);
  --project-card-background-shadow: rgb(185, 185, 189);
  --certificate-border-color: #0f0f0f;
  --certificate-background-color: rgb(244, 244, 248);
  --button-color: rgb(255, 255, 255);
}

[dark-theme="dark"] {
  --background-color: rgb(10, 8, 46);
  --primary-text-color: #d9d7d7;
  --description-color: #d9d7d7;
  --experience-card-background: rgb(10, 8, 46);
  --project-card-background: rgb(58, 57, 57);
  --project-card-background-mobile: rgb(10, 8, 46);
  --overview-text-color: #d9d7d7;
  --tool-bar-icon-color: #e1ebf4;
  --footer-background: rgb(10, 8, 46);
  --chip-background-color: #aba8a8;
  --chip-font-color: #0f0f0f;
  --experience-mobile-card-background: rgb(10, 8, 46);
  --experience-card-shadow: rgb(28, 23, 113);
  --project-card-background-shadow: rgb(89, 87, 87);
  --certificate-border-color: #679cf7;
  --certificate-background-color: rgb(16, 13, 78);
  --button-color: rgb(205, 202, 202);
}

body {
  font-family: "Montserrat", sans-serif;
}

.App {
  background-color: var(--background-color);
  color: var(--primary-text-color);
}

.app-root-container {
  width: 80%;
  margin: 0 auto;
}

.timeline-img {
  width: 90%;
}

.timeline-container {
  margin-bottom: 2rem !important;
}

.git-hub-calender-container {
  margin-bottom: 4rem;
}

.timeline-root-container {
  text-align: center;
}

.logo-toolbar {
  font-family: "Sofia", sans-serif;
  font-size: $sub-heading;
  color: var(--tool-bar-icon-color);
}

.toolbar-root-container {
  @include flex-row-space-between-container;
}

.toolbar-links-container {
  @include flex-row-space-between-container;
  align-items: center;
}

.tool-bar-Label {
  font-size: $project-description;
  color: var(--tool-bar-icon-color);
  font-family: "Inconsolata", sans-serif;
}

.tool-bar-icon {
  color: var(--tool-bar-icon-color) !important;
}

.tool-bar-Label::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: $darkest-blue;
  transition: width 0.3s ease;
}

.tool-bar-Label:hover::before {
  width: 100%;
}

.splitter {
  font-size: $sub-heading;
  margin-right: 2rem;
  margin-left: 2rem;
}

.line {
  width: 100%;
  height: 4px;
  background-color: $dark-blue;
  margin-left: 1rem;
}

.oval-chip {
  margin-right: 0.4rem;
  margin-bottom: 0.8rem;
}

.oval-chip-label {
  font-weight: bold !important;
  color: var(--chip-font-color) !important;
  background-color: var(--chip-background-color) !important;
}

.list-text {
  font-size: 1.1rem;
}

.skills-root-sub-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.skills-root-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: baseline;
}

.typography-style {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
}

// OverView Page
.overview-root-container {
  height: fit-content;
}

.overview-root-sub-container {
  @include flex-row-space-between-container;
  padding-top: 3rem;
}

.overview-welcome {
  font-size: $project-heading;
  color: var(--overview-text-color);
}

.overview-name {
  color: $blue-colour;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
  font-family: monospace;
  font-size: 3.5rem;
}

@keyframes typing {
  from {
    width: 0;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}

.overview-summary {
  color: var(--description-color);
  font-size: $subtitle;
  word-spacing: 0.1em; /* Adjust the value to control the gap size */
  line-height: 1.5;
}

.overview-summary-conclusion {
  color: var(--description-color);
  font-size: $subtitle;
  word-spacing: 0.1em; /* Adjust the value to control the gap size */
  margin-top: 1rem;
}
// Overview page

// Skills
.title-heading {
  font-size: $heading;
  color: $blue-colour;
  margin-bottom: 2rem;
  white-space: nowrap !important;
}

.skills-root-sub-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.skillcard-container {
  width: 60px;
  height: 60px;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.testimonials-arrow {
  color: var(--tool-bar-icon-color);
}

.skillcard-logo {
  width: 50px;
  height: 50px;
}

.skillcard-name {
  white-space: nowrap !important;
}
// Skills

// Project Card
.project-root-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: baseline;
}

.project-header {
  font-size: $heading;
  color: $blue-colour;
  margin-bottom: 4rem;
  white-space: nowrap !important;
}

.project-card-private-text {
  font-size: $sub-heading-small;
}

.project-card-private-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
}

.project-card-project-overview {
  font-size: $sub-heading;
}

.project-card-root-container {
  display: flex;
  flex-direction: row;
  height: fit-content;
  background-color: var(--project-card-background);
  border: 1px solid rgb(58, 57, 57);
  border-radius: 2rem;
  padding: 1rem;
  padding-bottom: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-bottom: 2rem;
}

.project-card-root-container {
  transition: box-shadow 0.3s;
}

.project-card-root-container:hover {
  box-shadow: 0 0px 4px 4px var(--project-card-background-shadow);
}

.project-card-project-name {
  font-size: $project-heading;
}

.project-card-project-subtitle {
  font-size: 0.9rem;
  margin: 0;
}

.project-card-project-date {
  font-size: $project-description;
}

.project-card-info {
  flex: 60%;
}

.project-show-more {
  font-size: 1rem;
}

.project-showmore-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.project-card-image {
  flex: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.project-logo-round {
  width: 200px;
  height: 200px;
  border-radius: 50%;
}

.project-logo {
  width: 250px;
  height: 150px;
}

.project-card-tech-chips {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.project-root-container {
  margin-bottom: 2rem;
}
// Project Card

// about me
.aboutme-container {
  padding-bottom: 5rem;
}
.about-me-root {
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
}

.about-me-para {
  line-height: 1.4;
}

.about-me-image {
  text-align: center;
  align-self: center;
  flex: 30%;
  margin-right: 2rem;
}

.about-me-content {
  flex: 70%;
}

.about-me-image {
  width: 300px;
  height: 300px;
  border-radius: 50%;
}
// about me

// certificate
.certificate-root-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.certificate-card-container {
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  flex: 40%;
  border-radius: 2rem;
  padding: 1rem;
  padding-bottom: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  border: 0.2px solid var(--certificate-border-color);
  background-color: var(--certificate-background-color);
}

.button-show-more {
  background-color: var(--button-color) !important;
}

.button-show-more {
  transition: box-shadow 0.3s;
}

.button-show-more:hover {
  box-shadow: 0 0px 4px 4px var(--project-card-background-shadow);
}

.certificate-card-container {
  transition: box-shadow 0.3s;
}

.certificate-card-container:hover {
  box-shadow: 0 0px 4px 4px var(--project-card-background-shadow);
}

.certificate-card-logo {
  width: 60px;
  height: 50px;
}

.certificate-card-details {
  text-align: center;
}

.certificate-card-logo-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.certificate-card-name {
  font-weight: 800;
  font-size: 1.2rem;
}
// certificate

// Test

.testimonials-root-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: baseline;
}

.MuiPaginationItem-root {
  color: var(--overview-text-color) !important;
}

.testimonials-root {
  margin-bottom: 2rem;
}

.testimonials-root-container {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
}

.testimonials-recommendation-root {
  border: 1px solid $blue-colour;
  border-radius: 2rem;
  display: flex;
  height: max-content;
  flex-direction: column;
  padding: 2rem;
}

.testimonials-recommendation-info {
  display: flex;
  flex-direction: row;
}
.testimonials-recommendation-personalInfo {
  margin-left: 2rem;
}

.testimonials-nav-button {
  align-self: center;
  height: 3rem;
}

.testimonials-recommendation-pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.testimonials-recommendation-personalInfo-name {
  font-size: $sub-heading;
  font-weight: bold;
}

.testimonials-recommendation-personalInfo-details {
  font-size: 1rem;
  margin: 0;
}

.testimonials-spllitter {
  display: block;
}

.testimonials-recommendation-relation {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.testimonials-realtion {
  line-height: 1.4;
}

.testimonials-buttons {
  display: block;
  align-self: center;
}
.testimonials-button-top {
  display: none;
}

.testimonials-recommendation-picture {
  width: 130px;
  height: 130px;
  border-radius: 50%;
}
// Test

// footer
.footer-root-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: var(--footer-background);
}
// footer

//hamburger

//hamburger
.hamburger-toolbar {
  display: none;
}
// media queries

// experience
.experience-company-heading {
  font-size: $sub-heading;
  font-weight: bold;
}

.experience-company-designation {
  font-size: $subtitle;
}

.experience-content-card-root-right {
  display: flex;
  justify-content: start;
}

.experience-content-card-root-left {
  display: flex;
  justify-content: end;
}

.experience-company-date {
  font-size: $sub-heading-small;
}

.experience-content-card {
  max-width: max-content;
  border: 0.2px solid $dark-blue;
  border-radius: 2rem;
  background-color: var(--experience-card-background);
  padding: 1.5rem;
}

.experience-content-card {
  transition: box-shadow 0.3s;
}

.experience-content-card:hover {
  box-shadow: 0 8px 8px 2px var(--experience-card-shadow);
}

.experience-content {
  text-align: left;
}

.experience-logo {
  width: 35px;
  height: 30px;
}

// experience mobile
.experience-desktop-main {
  display: block;
}

.experience-mobile-main {
  display: none;
}

.experience-mobile-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}

.experience-logo-mobile {
  width: 60px;
  height: 60px;
}

.experience-mobile-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.experience-mobile-image {
  margin-right: 2rem;
}

.experience-content-card-mobile {
  max-width: max-content;
  border: 1px solid $blue-colour;
  border-radius: 2rem;
  background-color: var(--experience-mobile-card-background);
  padding: 1.5rem;
}

.experience-content-card-mobile {
  transition: box-shadow 0.3s;
}

.experience-content-card-mobile:hover {
  box-shadow: 0 8px 8px 2px var(--experience-card-shadow);
}

.github-contributions {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.project-card-key-contributions-root {
  display: block;
}

// experince mobile
// experience

@media (max-width: 1050px) {
  .about-me-image {
    display: none;
  }
  .experience-desktop-main {
    display: none;
  }

  .experience-mobile-main {
    display: block;
  }
  .certificate-root-container {
    flex-direction: column;
  }
}

@media (max-width: 900px) {
  .project-card-image {
    display: none;
  }
  .hamburger-toolbar {
    display: block;
  }
  .toolbar-links-container {
    display: none;
  }
  .project-card-key-contributions-root {
    display: none;
  }
}

@media (max-width: 650px) {
  .testimonials-recommendation-info {
    flex-direction: column;
  }
  .testimonials-recommendation-relation {
    flex-direction: column;
    align-items: baseline;
  }
  .testimonials-recommendation-personalInfo {
    margin-left: 0.2rem;
  }
  .testimonials-spllitter {
    display: none;
  }
  .testimonials-realtion {
    margin-top: 0;
  }
  .testimonials-buttons {
    display: none;
  }
  .testimonials-root-container {
    flex-direction: column;
  }
  .testimonials-button-top {
    display: block;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 1rem;
  }
}

@media (max-width: 480px) {
  .project-header {
    font-size: $project-heading;
  }
  .overview-name {
    font-size: 2.4rem;
  }
  .overview-welcome {
    font-size: 1.6rem;
  }
  .project-header {
    white-space: wrap !important;
  }

  .skillcard-container {
    width: 30px;
    height: 30px;
    margin-left: 2rem;
    margin-right: 2rem;
    margin-bottom: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .skillcard-logo {
    width: 30px;
    height: 30px;
  }

  .skillcard-name {
    white-space: nowrap !important;
    font-size: 0.8rem;
  }

  .project-card-project-name {
    font-size: 1.4rem;
    font-weight: bold;
  }

  .project-card-project-overview,
  .project-card-project-date,
  .list-text {
    font-size: $subtitle;
  }

  .project-card-root-container {
    background-color: var(--project-card-background-mobile);
    border: 1px solid $blue-colour;
  }

  .oval-chip-label {
    font-size: 0.7rem;
  }
}

// media queries
